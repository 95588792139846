<template>
  <fragment>
    <v-tooltip top :disabled="!isBillingDisabled">
      <template v-slot:activator="{ on, attrs }">
        <div v-on="on" v-bind="attrs">
          <v-btn
            :color="color"
            :text="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            rounded
            class="text-uppercase"
            @click="payInvoice"
            :loading="paymentPending"
            :small="small"
            :disabled="isBillingDisabled"
          >
            <template v-slot:loader>
              <div class="d-flex">
                <v-icon
                  class="mr-1 mr-m0"
                  :color="$vuetify.breakpoint.smAndDown ? 'white' : 'primary'"
                >
                  mdi-credit-card-fast-outline
                </v-icon>
                <div style="min-width: 0; white-space: pre-wrap">
                  {{ $t('patientPortal.invoices.paymentButton.inProgress') }}
                </div>
              </div>
            </template>
            <v-icon class="mr-1">mdi-credit-card-outline</v-icon>{{ text }}</v-btn
          >
        </div>
      </template>
      <span>{{ $t('patientPortal.invoices.unavailable') }}</span>
    </v-tooltip>
    <v-dialog v-model="dialogOpen" persistent max-width="400px">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <div>
            {{ $t('patientPortal.invoices.paymentButton.pay') }}
          </div>
          <v-btn icon @click="dialogOpen = false"><v-icon>mdi-close</v-icon> </v-btn></v-card-title
        >
        <v-card-subtitle>
          {{ fileName }}
        </v-card-subtitle>
        <v-card-text>
          <i18n path="patientPortal.invoices.paymentButton.instructions" tag="span">
            <template v-slot:action>
              <strong>{{ $t('patientPortal.invoices.paymentButton.refresh') }} </strong>
            </template>
          </i18n>
          <br />
          <br />
          {{ $t('patientPortal.invoices.paymentButton.assistance') }}.
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-btn color="primary" rounded block @click="goPayment">
            {{ $t('patientPortal.invoices.paymentButton.payNow') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>
<script>
export default {
  data() {
    return {
      dialogOpen: false,
      paymentPending: false,
    };
  },
  props: {
    color: {
      type: String,
      default() {
        return 'primary';
      },
    },
    fileName: String,
    invoiceUrl: String,
    small: Boolean,
    text: {
      type: String,
      default() {
        return 'Pay invoice';
      },
    },
  },
  computed: {
    isBillingDisabled() {
      return this.$store.getters['patientPortal/isBillingDisabled'];
    },
  },
  methods: {
    goPayment() {
      this.dialogOpen = false;
      this.paymentPending = true;
      this.setWindowFocusWatcher();
      window.open(this.invoiceUrl, '_blank');
    },
    onTabRefocus() {
      if (document.visibilityState !== 'visible') return;
      this.$emit('refresh');
      document.removeEventListener('visibilitychange', this.onTabRefocus);
    },
    payInvoice() {
      if (this.isBillingDisabled) return;
      this.dialogOpen = true;
    },
    setWindowFocusWatcher() {
      document.addEventListener('visibilitychange', this.onTabRefocus);
    },
  },
};
</script>
